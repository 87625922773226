import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Dr. Molly Miller"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Molly Miller"
        videoUrl="https://vimeo.com/498095877/6c4d88e0b6"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.mollyMillerImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Dr. Molly Miller
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and performing artist
            </Text>
            <Text level="1" mb="1.2rem">
              Chair of the Guitar Department at LA College of Music
            </Text>
            <Box>
              <Link to="https://www.instagram.com/moodymill" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/moodymill/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Since Dr. Molly Miller picked up a guitar at age seven, she’s been
              captivating audiences with her sophisticated and raw style. She’s
              one of Los Angeles’s most sought-after musicians, recording and
              touring with artists such as Jason Mraz, Black Eyed Peas, Donna
              Missal, and Morgxn at venues such as the Hollywood Bowl, Royal
              Albert Hall, and Coachella. In the renowned magazine, Guitar,
              Michael James Adam notes, “She slings the guitar like a wordsmith,
              wry and sarcastic with a playful slant and flows from hushed
              whisper to bursts of elation with aplomb”. Miller is featured on
              Jason Mraz’s latest albums Know and Look For the Good. She is in
              the house-band on The Bachelor’s newest ABC primetime show, Listen
              To Your Heart.
            </Text>
            <Text level="2">
              Soon after earning her Doctorate in Musical Arts from the
              University of Southern California in 2016, she became the Chair of
              the Guitar Department at Los Angeles College of Music, a position
              she still holds. When Miller’s not on tour, running a guitar
              department, or in the studio, she heads her own trio, Molly Miller
              Trio, showcased on NPR’s Fresh Air.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Molly Miller" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    mollyMillerImage: file(
      relativePath: { eq: "mollyMiller-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
